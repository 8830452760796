<template>
    <div>
        <div class="d-flex flex-column align-items-center bg-green rounded p-5">
            <span :class="`rounded-circle p-3 text-white bg-white`">
                <b-icon class="d-flex text-purple" icon="chat-left-dots-fill" font-scale="3"></b-icon>
            </span>
            <div class="text-white fs-4 mb-2">Templates HSM</div>
            <b-button variant="light" class="text-purple px-4" @click="$router.replace('/templateHSM')">Criar</b-button>
        </div>
        <div class="mt-4 bg-white rounded text-purple">
            <div class="d-flex justify-content-between align-items-center border-bottom p-3">
                <div class="fs-5 fw-bold">Templates</div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Templates</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2 py-1">{{ models.length || 0 }}</div>
                    <b-dropdown ref="dropdownFilters" right menu-class="p-0 mt-2 shadow-sm w-max" toggle-class="border border-dark-subtle rounded-pill px-3 py-2 text-secondary small-text hstack gap-2" variant="white" text="Filtrar">
                        <b-form class="vstack gap-3 p-3" @submit.prevent="filterModels">
                            <b-form-group class="w-100" label="Nome:" label-class="text-purple">
                                <b-form-input type="text" class="border-grey" placeholder="Nome" v-model="filters.name"/>
                            </b-form-group>
                            <b-form-group class="w-100" label="Status:" label-class="text-purple">
                                <b-form-select class="w-100 border-grey rounded py-1-5 px-2" v-model="filters.status">
                                    <b-form-select-option :value="null">
                                        Todos
                                    </b-form-select-option>
                                    <b-form-select-option value="approved">
                                        Aprovado
                                    </b-form-select-option>
                                    <b-form-select-option value="rejected">
                                        Rejeitado
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="w-100" label="Categoria:" label-class="text-purple">
                                <b-form-select class="w-100 border-grey rounded py-1-5 px-2" v-model="filters.category">
                                    <b-form-select-option :value="null">
                                        Todas
                                    </b-form-select-option>
                                    <b-form-select-option value="marketing">
                                        Marketing
                                    </b-form-select-option>
                                    <b-form-select-option value="utility">
                                        Utilidade
                                    </b-form-select-option>
                                    <b-form-select-option value="authentication">
                                        Autenticação
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="w-100" label="Função:" label-class="text-purple">
                                <b-form-select class="w-100 border-grey rounded py-1-5 px-2" v-model="filters.type">
                                    <b-form-select-option :value="null">
                                        Todas
                                    </b-form-select-option>
                                    <b-form-select-option value="active_communication">
                                        Atendimento
                                    </b-form-select-option>
                                    <b-form-select-option value="campaign">
                                        Campanha
                                    </b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-button type="submit" class="text-white" variant="green">Filtrar</b-button>
                        </b-form>
                    </b-dropdown>
                    <!-- <b-form-input type="text" placeholder="Busca por Nome Template" @input="filterModels"></b-form-input> -->
                    <b-button class="bg-pink border-0 ms-3" v-b-modal.modalTags>Tags</b-button>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center bg-white rounded p-3" v-if="loading">
                <b-spinner />
            </div>
            <div v-else-if="models.length">
                <b-row class="m-0 gap-2 border-bottom px-3 py-2">
                    <b-col class="p-0">
                        <div class="fw-bold">Nome</div>
                    </b-col>
                    <b-col class="p-0">
                        <div class="fw-bold">Qualidade</div>
                    </b-col>
                    <b-col class="p-0" cols="2">
                        <div class="fw-bold">Categoria</div>
                    </b-col>
                    <b-col class="p-0" cols="2">
                        <div class="fw-bold">Status</div>
                    </b-col>
                    <b-col class="p-0" cols="2">
                        <div class="fw-bold">Função</div>
                    </b-col>
                    <b-col class="p-0" cols="2"></b-col>
                </b-row>
                <b-row class="m-0 gap-2 px-3 py-3 border-bottom d-flex align-items-center models" v-for="item in models" :key="item.id">
                    <b-col class="p-0 text-truncate">
                        <div class="text-truncate">{{ item.elementName }}</div>
                    </b-col>
                    <b-col class="p-0 text-truncate">
                        <div class="text-capitalize">{{ formatQuality(item.quality) }}</div>
                    </b-col>
                    <b-col class="p-0 text-truncate" cols="2">
                        <div class="text-capitalize">{{ formatCategory(item.category) }}</div>
                    </b-col>
                    <b-col class="p-0 text-truncate" cols="2">
                        <div class="hstack gap-2 text-capitalize">
                            {{ formatStatus(item.status) }}
                            <div v-b-tooltip.hover.bottom="{ variant: 'purple', customClass: 'top-0', boundary: 'document' }" :title="item.reason" v-if="item.reason && (item.status === 'REJECTED' || item.status === 'FAILED')" role="button">
                                <b-iconstack class="darkenTextOnHover" role="button" font-scale="1.3"> 
                                    <b-icon class="text-purple" stacked icon="circle-fill"></b-icon>
                                    <b-icon stacked class="text-white" icon="info"></b-icon>
                                </b-iconstack>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0 text-truncate" cols="2">
                        <div class="text-capitalize">{{ formatType(item.type) }}</div>
                    </b-col>
                    <b-col class="p-0 hstack justify-content-end gap-1" cols="2">
                        <b-button variant="purple" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'purple', customClass: 'top-0', boundary: 'document' }" title="Preview" @click.prevent="selectModel(item)" v-b-modal.modalPreview>
                            <b-icon icon="eye"></b-icon>
                        </b-button>
                        <b-button variant="green" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Editar" @click.prevent="$router.replace(`/templateHSM?id=${item.id}`)" :disabled="item.status !== 'APPROVED'">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button>
                        <b-button variant="primary" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'primary', customClass: 'top-0', boundary: 'document' }" title="Teste de Template" @click.prevent="selectModel(item)" v-b-modal.testTemplate :disabled="item.status !== 'APPROVED'">
                            <b-icon icon="arrow-return-right"></b-icon>
                        </b-button>
                        <b-button variant="red" class="text-white manage-btn mx-0" v-b-tooltip.hover.bottom="{ variant: 'red', customClass: 'top-0', boundary: 'document' }" title="Remover" @click.prevent="selectModel(item)" v-b-modal.deleteHSM>
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="p-3 text-purple text-center" v-else>
                Nenhum modelo encontrado!
            </div>
        </div>
        <b-modal id="modalTags" ref="modalTags" title="Tags" hide-footer body-class="p-0" header-class="py-0">
            <div class="border-bottom py-2 px-3" v-for="item in tags" :key="item.id">
                <b style="text-transform: uppercase">{{item.name}}</b> - {{item.description}}
            </div>
        </b-modal>
        <b-modal id="modalPreview" ref="modalPreview" :title="`Preview: ${selectedModel.elementName}`" hide-footer body-class="p-0" header-class="py-0" v-if="selectedModel">
            <div class="p-3">
                <Whatsapp :messages="messages" :height="'40vh'" :mediaURL="mediaURL" />
                <!-- <div class="mt-3">
                    <b-button class="btn-success" @click="$bvModal.hide('modalPreview')">Fechar</b-button>
                </div> -->
            </div>
        </b-modal>
        <b-modal id="testTemplate" ref="testTemplate" title="Teste de Template" hide-footer body-class="p-0" header-class="py-0" v-if="selectedModel">
            <div class="p-3 vstack gap-3">
                <Whatsapp :messages="messages" :height="'40vh'" :mediaURL="mediaURL" />
                <b-form @submit.prevent="sendTemplate()" class="hstack align-items-end gap-2 w-100">
                    <b-form-group class="w-100" label="Enviar para:" label-class="text-purple">
                        <b-input-group>
                            <b-input-group-append class="bg-grey rounded-start d-flex align-items-center justify-content-center p-2">
                                <b-icon class="text-purple" font-scale="1.25" icon="telephone" />
                            </b-input-group-append>
                            <b-form-input type="tel" required v-model="testNumber" />
                        </b-input-group>
                    </b-form-group>
                    <b-button type="submit" class="text-white" variant="green" :disabled="sendingTest">
                        <b-spinner small v-if="sendingTest"/>
                        Enviar
                    </b-button>
                </b-form>
            </div>
        </b-modal>
        <b-modal id="deleteHSM" ref="deleteHSM" title="Remover Template" hide-footer body-class="p-0" header-class="py-0" size="sm" v-if="selectedModel">
            <form class="vstack" @submit.prevent="deleteModel()">
                <div class="text-secondary smaller-text p-3">Tem certeza de que deseja remover este Template?</div>
                <div class="hstack gap-2 border-top p-3">
                    <b-button type="submit" class="text-white px-3 hstack gap-2" :disabled="deletingHSM" variant="green">
                        <b-spinner small v-if="deletingHSM"/>
                        Remover
                    </b-button>
                    <b-button class="border-grey" variant="outline-secondary" @click="$bvModal.hide('deleteHSM')">Cancelar</b-button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import api from '../services/apiService.js'
import Whatsapp from './whatsapp.vue'

export default {
    components: {
        Whatsapp
    },
    props: [
        'user', 'mediaURL'
    ],
    data() {
        return {
            selectedModel: null,
            deletingHSM: false,
            sendingTest: false,
            loading: false,
            models: [],
            testNumber: "",
            filters: {
                status: null,
                category: null,
                type: null
            },
            channels: [],
            tags: [],
            loadingTags: false,
        }
    },
    mounted() {
        if (this.user.channel) {
            this.channels.push(this.user.channel)
            this.getModels()
        } else {
            this.loading = true
            setTimeout(() => {
                if (this.user.channel)
                    this.channels.push(this.user.channel)
                this.getModels()
            }, 2000);
        }
        this.getTags()
        if(this.$route.query && !(Object.keys(this.$route.query).length === 0 && this.$route.query.constructor === Object)) {
            const pending = this.$route.query.pending
            
            if (pending === 'true') {
                this.$emit("msg", {
                    text: "Template em aprovação!",
                    success: true
                })

                this.$router.replace('/manageHSMModels')
            }
        }
        this.onCloseModal()
    },
    computed: {
        messages() {
            let template = this.selectedModel

            if (template.containerMeta) {
                const containerMeta = JSON.parse(template.containerMeta)
    
                if (containerMeta) {
                    template = { ...template, ...containerMeta, content: containerMeta.data, example: containerMeta.sampleText, url: containerMeta.mediaUrl }
                }
            }


            return [{ type: 'template', body: { ...template, originalType: template.type, type: 'template' }, fromMe: true }]
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                this.testNumber = ""
            })
        },
        async getTags() {
            this.loadingTags = true
            
            const tags = [
                {
                    name: "DTN",
                    description: "Dia, Tarde ou Noite"
                },
                {
                    name: "NOME_CLIENTE",
                    description: "Nome do Contato"
                },
                {
                    name: "APELIDO_CLIENTE",
                    description: "Apelido do Contato"
                },
            ]
            
            const resp = await api.getMessageTags(this.user.channelId || this.user.roleId)

            this.loadingTags = false

            if (resp.statusCode === 200) {
                tags.push(...resp.tags)
            }

            this.tags = tags
        },
        selectModel(model) {
            this.selectedModel = model
        },
        async getModelsByChannelId(channelId) {
            let templates = []

            const campaignTemplates = await api.getHsmTemplates(channelId, 'campaign')
            const attendanceTemplates = await api.getHsmTemplates(channelId, 'active_communication')
            
            if(campaignTemplates.statusCode === 200)
                templates = [...templates, ...campaignTemplates.templates.map(el => {el.type = 'campaign'; return el})]

            if(attendanceTemplates.statusCode === 200)
                templates = [...templates, ...attendanceTemplates.templates.map(el => {el.type = 'active_communication'; return el})]

            console.log({templates})

            return templates
        },
        async getModels() {
            this.loading = true
            let templates = []

            if (this.channels.length <= 1) {
                const secondaryChannels = await this.getSecondaryChannels()
                this.channels.push(...secondaryChannels.filter((channel) => channel.apiType === 'gupshup' || channel.apiType === 'cloud'))
            }

            const promise = this.channels.map(async channel => {
                if (channel.apiType === 'gupshup' || channel.apiType === 'cloud') {
                    const channelModels = await this.getModelsByChannelId(channel._id)
                    templates.push(...channelModels)
                }
            })
            await Promise.all(promise)

            if (templates[0]?.components) {
                templates = templates.map(template => {
                    template.elementName = template.name
                    template.content = template.components.find(el => el.type === 'BODY')?.text
                    template.languageCode = template.language
                    template.templateType = template.components.find(el => el.type === 'HEADER')?.format || 'TEXT' 
                    template.header = template.components.find(el => el.type === 'HEADER')?.text
                    template.footer = template.components.find(el => el.type === 'FOOTER')?.text
                    template.buttons = template.components.find(el => el.type === 'BUTTONS')?.buttons
                    template.url = template.components.find(el => el.type === 'HEADER')?.example?.header_handle?.[0]

                    return template
                })
            }
            

            this.loading = false

            this.models = templates.sort((a,b) => b.createdOn - a.createdOn)
        },
        async getSecondaryChannels() {
            const resp = await api.getSecondaryChannels(this.user.channelId || this.user.roleId)
            
            if(resp.statusCode === 200)
                return resp.channels

            return []
        },
        formatStatus(status) {
            switch(status) {
                case 'APPROVED':
                    return "Aprovado"
                case 'PENDING':
                    return "Em Aprovação"
                case 'REJECTED':
                    return "Reprovado"
                case 'FAILED':
                    return "Falha"
                default:
                    return "- - - -"
            }
        },
        formatCategory(category) {
            switch(category) {
                case 'MARKETING':
                    return "Marketing"
                case 'UTILITY':
                    return "Utilidade"
                case 'AUTHENTICATION':
                    return "Authenticação"
                case 'TRANSACTIONAL':
                    return "Transacional"
                default:
                    return category
            }
        },
        formatQuality(quality) {
            switch(quality) {
                case undefined:
                case null:
                case 'UNKNOWN':
                    return "Desconhecido"
                default:
                    return quality
            }
        },
        formatType(type) {
            switch(type) {
                case 'campaign':
                    return "Campanha"
                case 'active_communication':
                    return "Atendimento"
                default:
                    return "- - - -"
            }
        },
        async filterModels() {
            this.loading = true

            const resp = await api.getHsmTemplatesFilter(this.user.channelId || this.user.roleId, this.filters)
            console.log({resp})

            this.loading = false

            if (resp.statusCode === 200) {
                this.models = resp.templates.sort((a,b) => b.createdOn - a.createdOn)
            } else {
                this.models = []
            }
            // await this.getModels()
            // this.models = this.models.filter(el => el.name.toLowerCase().includes(filter))
        },
        async deleteModel(model) {
            if (!this.deletingHSM) {
                this.deletingHSM = true
                
                if (!model) {
                    model = this.selectedModel
                }
    
                if (model) {
                    const resp = await api.deleteHSM(model.channelId || this.user.channelId || this.user.roleId, model.elementName)
    
                    if (resp.statusCode === 200 || resp.statusCode === 201) {
                        this.$emit("msg", {
                            text: "Template removido com sucesso!",
                            success: true
                        })
                        
                        this.getModels()

                        this.filters = {
                            status: null,
                            category: null,
                            type: null
                        }

                        this.$bvModal.hide('deleteHSM')
                    } else {
                        this.$emit("msg", {
                            text: "Ocorreu um erro!",
                            success: false
                        })
                    }
                }

                this.deletingHSM = false
            }
        },
        async sendTemplate(template) {
            if (!this.sendingTest) {
                this.sendingTest = true

                if (!template)
                    template = this.selectedModel

                const resp = await api.sendHSM(template.channelId || this.user.channelId || this.user.roleId, this.testNumber, template.elementName)

                if (resp.statusCode === 200) {
                    this.$emit("msg", {
                        text: "Template adicionado a fila!",
                        success: true
                    })

                    this.$bvModal.hide('testTemplate')
                } else {
                    this.$emit("msg", {
                        text: "Ocorreu um erro!",
                        success: false
                    })
                }

                this.sendingTest = false
            }
        },
    }
}
</script>

<style scoped>
    .models:last-child, #modalTags div:last-child {
        border: none !important;
    }
</style>